<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Relawan</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            @on-selected-rows-change="handleSelectionChange"
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Masukkan NIK atau Nama',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
            :selectOptions="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'text-dark px-1',
              selectionText: 'data dipilih',
              clearSelectionText: '',
            }"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="selected-row-actions">
              <button
                v-on:click="confirmAllDelete"
                class="btn btn-xs btn-danger m-r-5"
              >
                <i class="fa fa-trash-alt"></i> Hapus Data Terpilih
              </button>
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="loading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.id_relawan)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "NIK",
          field: "nik_relawan",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "nama_relawan",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-uppercase",
        },
        {
          label: "HP",
          field: "hp_relawan",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kabupaten/Kota",
          field: "nama_kab",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "nama_kec",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Desa/Kelurahan",
          field: "nama_kel",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Target",
          field: "target_relawan",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Capaian",
          field: "capaian",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Persentasi",
          field: "persentasi",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "75px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      dataTerpilih: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleSelectionChange(selectedRows) {
      if (Array.isArray(selectedRows.selectedRows)) {
        this.dataTerpilih = selectedRows.selectedRows.map(
          (row) => row.id_relawan
        );
      } else {
        this.dataTerpilih = [];
      }
    },
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/relawan/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/relawan/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    confirmAllDelete() {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Terpilih Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteAllData();
        }
      });
    },
    deleteAllData() {
      const formData = new FormData();
      formData.append("data", this.dataTerpilih);
      axios
        .post("/v1/relawan/delete/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Sukses",
              text: "Data Berhasil Dihapus",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.fetchData();
            });
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.error = error.message;
        });
    },
  },
};
</script>